import React from 'react'

const Twitter = () => (
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.229492 13.0956C1.88171 14.2111 3.86315 14.8915 6.01225 14.9541C13.077 15.1597 17.2445 9.38153 17.1617 4.08808C17.9208 3.58046 18.5857 2.93733 19.1183 2.19756C18.4266 2.47662 17.6874 2.65746 16.9157 2.72562C17.7142 2.28456 18.3397 1.56525 18.6499 0.695165C17.9017 1.10454 17.078 1.39364 16.2069 1.53758C15.5342 0.786584 14.5591 0.301424 13.4643 0.269748C11.0083 0.198378 9.13522 2.40966 9.61992 4.77692C6.46204 4.52833 3.7028 2.95056 1.89429 0.621389C0.846942 2.28216 1.2606 4.50628 2.92216 5.67427C2.29862 5.63658 1.71608 5.44973 1.21189 5.15342C1.11893 6.89398 2.33394 8.55917 4.153 8.97536C3.61268 9.10407 3.02527 9.1193 2.43299 8.98939C2.87182 10.4974 4.24515 11.6241 5.90954 11.7035C4.27316 12.8955 2.24138 13.3899 0.229492 13.0956Z"
      fill="#242424"
    />
  </svg>
)

export default Twitter
