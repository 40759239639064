import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Link } from '@superrb/gatsby-addons/components'
import { MainHeaderLink } from '../types/main-header'
import { NavContext } from '@superrb/gatsby-addons/context'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import MenuToggle from './menu-toggle'
import Logo from './images/logo.svg'
import { ContactPanelContext } from '../context/contact-panel-context-provider'
import Button, { ButtonStyle } from './button'
import Social from './social'

const navigationItems = [
  {
    label: 'About',
    link: {
      url: '/#about',
    },
  },
  {
    label: 'Services',
    link: {
      url: '/#services',
    },
  },
  {
    label: 'Summit',
    link: {
      url: '/#summit',
    },
  },
  {
    label: 'Careers',
    link: {
      target: '_blank',
      url: 'https://careers.zigguratxyz.com',
    },
  },
]

const Header = () => {
  const isMobile = useIsMobile()
  const { navOpen, closeNav } = useContext(NavContext)
  const headerElement = useRef<HTMLElement>(
    null,
  ) as MutableRefObject<HTMLElement>
  const [frontendReady, setFrontendReady] = useState<boolean>(false)

  useEffect(() => {
    setFrontendReady(true)
  }, [])

  const handleScroll = useCallback(() => {
    requestAnimationFrame(() => {
      //If header needs to be sticky, do it here
    })
  }, [])

  const { openContactPanel } = useContext(ContactPanelContext)

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll, { passive: false })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <header
      className={`main-header ${navOpen ? 'main-header--nav-open' : ''}`}
      ref={headerElement}
      aria-hidden={!frontendReady}
    >
      <div className="main-header__container">
        <Link to="/" id="logo" className="main-header__logo" onClick={closeNav}>
          <span className="screenreader-text">Back To Home</span>
          <Logo />
        </Link>
        {isMobile && (
          <MenuToggle
            className="main-header__toggle"
            label="Open Nav"
            closeLabel="Close Nav"
            aria-controls="nav"
          />
        )}
        <nav
          id="nav"
          className={`main-header__nav nav ${navOpen ? 'nav--visible' : ''}`}
        >
          <ul className="nav__list">
            {navigationItems.map((link: MainHeaderLink, index: number) => (
              <li key={index} className="nav__item">
                <Link
                  to={link.link.url}
                  className="nav__link"
                  onClick={closeNav}
                  target={link.link.target}
                >
                  <span className="nav__label">{link.label}</span>
                </Link>
              </li>
            ))}

            <li className="nav__button">
              <Button
                className="nav__contact"
                buttonStyles={[
                  ButtonStyle.tertiary,
                  ButtonStyle.transparent,
                  ButtonStyle.border,
                  ButtonStyle.arrow,
                ]}
                label="Contact Us"
                onClick={() => {
                  closeNav()
                  openContactPanel()
                }}
              />
            </li>

            {isMobile && (
              <li className="nav__social">
                <Social
                  className="nav__social"
                  buttonStyles={[ButtonStyle.secondary, ButtonStyle.border]}
                />
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
