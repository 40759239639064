import React from 'react'

const Linkedin = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_209_333)">
      <path
        d="M4.93853 20.7802H1.09067V6.47795H4.93853V20.7802ZM3.01363 4.82868C1.77551 4.82868 0.769531 3.80107 0.769531 2.53617C0.769531 1.27127 1.77551 0.241699 3.01363 0.241699C4.25176 0.241699 5.25773 1.26931 5.25773 2.53617C5.25773 3.80303 4.25369 4.83064 3.01363 4.83064V4.82868ZM20.33 20.7802H16.4821V13.4928C16.4821 9.11366 11.3516 9.44508 11.3516 13.4928V20.7802H7.50377V6.47795H11.3516V8.77243C13.143 5.40916 20.33 5.16207 20.33 11.9925V20.7802Z"
        fill="#242424"
      />
    </g>
    <defs>
      <clipPath id="clip0_209_333">
        <rect
          x="0.769531"
          y="0.241699"
          width="19.5604"
          height="20.5385"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Linkedin
