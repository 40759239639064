import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'
import MainFooter from '../types/main-footer'
import LogoIcon from './images/logo-icon.svg'
import SiteConfig from '../types/site-config'
import { Link } from '@superrb/gatsby-addons/components'
import { TranslationContext } from '@superrb/gatsby-addons/context'
import Social from './social'
import { CookieBanner } from './cookie-banner'

const Footer = () => {
  const data: {
    footer: MainFooter
    config: SiteConfig
  } = useStaticQuery(graphql`
    query MainFooterQuery {
      footer: prismicMainFooter {
        ...MainFooter
      }
      config: prismicSiteConfig {
        ...LocalSiteConfig
      }
    }
  `)

  const { translate } = useContext(TranslationContext)

  /** @type {MainFooter} footer */
  const footer = data.footer
  if (!footer) {
    return null
  }

  const config = data.config

  return (
    <>
      <footer className="footer">
        <div className="footer__inner">
          <LogoIcon />

          <div className="footer__contact">
            {config.data?.email_address && (
              <a
                className="footer__contact-link footer__contact-link--email"
                href={`mailto:${config.data.email_address}`}
              >
                {config.data.email_address}
              </a>
            )}
            {config.data.phone_number && (
              <a
                className="footer__contact-link footer__contact-link--tel"
                href={`tel:${config.data.phone_number}`}
              >
                {config.data.phone_number}
              </a>
            )}
          </div>

          <Social className="footer__social" />

          <nav className="footer__nav">
            <ul className="footer__nav-list">
              {data.footer.data.navigation_items.map((item, index) => (
                <li className="footer__nav-item" key={index}>
                  <Link to={linkResolver(item.link)} className="footer__nav-link">
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          <span
            className="footer__attribution"
            dangerouslySetInnerHTML={{
              __html: translate('footer.attribution')
                .replace('{year}', new Date().getFullYear().toString())
                .split('. ')
                .map(
                  (string) => `
                <span class="footer__attribution-item">${
                  string.endsWith('.') ? string : `${string}.`
                }</span>
              `,
                )
                .join(''),
            }}
          ></span>
        </div>
        <CookieBanner />
      </footer>
    </>
  )
}

export default Footer
