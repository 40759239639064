import { Button as BaseButton } from '@superrb/gatsby-addons/components'
import { ButtonProps } from '@superrb/gatsby-addons/src/components/button'
import React, { memo, PropsWithChildren } from 'react'

export enum ButtonStyle {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
  back = 'back',
  arrow = 'arrow',
  border = 'border',
  circle = 'circle',
  icon = 'icon',
  transparent = 'transparent',
}

type Props = PropsWithChildren<ButtonProps> & {
  buttonStyles?: ButtonStyle[]
}

const Button = ({
  className = '',
  buttonStyles = [ButtonStyle.primary],
  children,
  ...props
}: Props) => (
  <BaseButton
    className={`${buttonStyles
      .map((style) => `button--${style}`)
      .join(' ')} ${className}`}
    {...props}
  >
    {children}
  </BaseButton>
)

export type { Props as ButtonProps }

export default memo(Button)
