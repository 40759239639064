import React, { MutableRefObject, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Image } from '@superrb/gatsby-addons/components'
import { useParallax } from '@superrb/gatsby-addons/hooks'
import { ImageLayout, Image as ImageType } from '@superrb/gatsby-addons/types'

const ContactPanelImages = ({ parallax = false }) => {
  const data = useStaticQuery(graphql`
    query ContactPanelImagesQuery {
      images: allFile(
        filter: {
          relativePath: {
            in: ["contact/Vector-1.png", "contact/ziggurat-07 3.png"]
          }
        }
        sort: { fields: relativePath }
      ) {
        nodes {
          childImageSharp {
            fluid(
              srcSetBreakpoints: [
                25
                125
                250
                375
                750
                1080
                1366
                1920
                2560
                3840
                4096
                5120
              ]
            ) {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  `)

  const images: ImageType[] = data?.images?.nodes.map(
    (node) => node.childImageSharp.fluid,
  )

  const imageRefs = useRef<HTMLElement[]>([]) as MutableRefObject<HTMLElement[]>
  useParallax(imageRefs.current, parallax)

  return (
    <div className="contact-panel__images">
      {images.map(({ srcWebp, srcSetWebp }, index) => (
        <Image
          key={index}
          ref={(ref: HTMLElement) => imageRefs.current.push(ref)}
          image={{
            alt: '',
            fluid: { src: srcWebp, srcSet: srcSetWebp },
          }}
          layout={ImageLayout.contain}
        />
      ))}
    </div>
  )
}

export default ContactPanelImages
