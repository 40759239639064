import React from 'react'

const Close = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8019 0.925557L0.836541 11.4665"
      stroke="#242424"
      strokeWidth="1.53518"
    />
    <path
      d="M0.548725 1.21344L11.0896 11.1788"
      stroke="#242424"
      strokeWidth="1.53518"
    />
  </svg>
)

export default Close
