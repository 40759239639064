import React from 'react'

const LogoIcon = () => (
  <svg
    width="86"
    height="75"
    viewBox="0 0 86 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_267_4011)">
      <path
        d="M58.4707 67.4013L71.3829 74.9999V59.8027L58.4707 52.2041V67.4013Z"
        fill="#06DD7E"
      />
      <path
        d="M86.0001 52.2041L73.0879 59.8027V74.9999L86.0001 67.4013V52.2041Z"
        fill="#98EF60"
      />
      <path
        d="M56.7647 52.2041L43.8525 59.8027V74.9999L56.7647 67.4013V52.2041Z"
        fill="#05DDE2"
      />
      <path
        d="M56.7647 0L43.8525 7.59861V22.7983L56.7647 15.1996V0Z"
        fill="#FFC373"
      />
      <path
        d="M56.7647 33.7007L43.8525 26.1021V41.2993L56.7647 48.9003V33.7007Z"
        fill="#FF3740"
      />
      <path
        d="M72.1138 26.1021L58.4707 33.7007V48.9003L72.1138 41.2993V26.1021Z"
        fill="#D647C2"
      />
      <path
        d="M27.5294 67.4013L14.6172 74.9999V59.8027L27.5294 52.2041V67.4013Z"
        fill="#2D56C7"
      />
      <path
        d="M0 52.2041L12.9122 59.8027V74.9999L0 67.4013V52.2041Z"
        fill="#7C47BF"
      />
      <path
        d="M29.2354 52.2041L42.1475 59.8027V74.9999L29.2354 67.4013V52.2041Z"
        fill="#0389E8"
      />
      <path
        d="M29.2354 0L42.1475 7.59861V22.7983L29.2354 15.1996V0Z"
        fill="#FFF32E"
      />
      <path
        d="M29.2354 33.7007L42.1475 26.1021V41.2993L29.2354 48.9003V33.7007Z"
        fill="#FF6D55"
      />
      <path
        d="M13.8877 26.1021L27.5308 33.7007V48.9003L13.8877 41.2993V26.1021Z"
        fill="#FF9642"
      />
    </g>
    <defs>
      <clipPath id="clip0_267_4011">
        <rect width="86" height="75" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default LogoIcon
