import React from 'react'
import { MenuToggle as BaseMenuToggle } from '@superrb/gatsby-addons/components'
import Menu from './images/menu.svg'
import MenuClose from './images/menu-close.svg'
import { MenuToggleProps } from '@superrb/gatsby-addons/src/components/menu-toggle'

const MenuToggle = (props: MenuToggleProps) => (
  <BaseMenuToggle
    {...props}
    aria-controls="nav"
    className={'button button--icon button--transparent'}
    renderIcon={(navOpen) => (navOpen ? <MenuClose /> : <Menu />)}
  />
)

export default MenuToggle
