import { graphql, useStaticQuery } from 'gatsby'
import React, { memo } from 'react'
import SiteConfig from '../types/site-config'
import { ButtonStyle } from './button'
import IconButton from './icon-button'
import Instagram from './images/instagram.svg'
import Linkedin from './images/linkedin.svg'
import Twitter from './images/twitter.svg'

const Social = ({
  className = '',
  buttonStyles = [ButtonStyle.secondary],
}: {
  className?: string
  buttonStyles?: ButtonStyle[]
}) => {
  const data: { config: SiteConfig } = useStaticQuery(graphql`
    query SocialQuery {
      config: prismicSiteConfig {
        ...LocalSiteConfig
      }
    }
  `)

  /** @type {SiteConfig} config */
  const config = data.config
  if (!config) {
    return null
  }

  return (
    <nav className={`social ${className}`}>
      <ul className="social__list">
        {config.data?.instagram_username && (
          <li className="social__item">
            <IconButton
              className="social__icon"
              buttonStyles={buttonStyles}
              href={`https://instagram.com/${config.data.instagram_username}`}
              target="_blank"
              rel="noopener"
            >
              <span className="screenreader-text">Follow us on Instagram</span>
              <Instagram />
            </IconButton>
          </li>
        )}

        {config.data?.twitter_username && (
          <li className="social__item">
            <IconButton
              className="social__icon"
              buttonStyles={buttonStyles}
              href={`https://twitter.com/${config.data.twitter_username}`}
              target="_blank"
              rel="noopener"
            >
              <span className="screenreader-text">Follow us on Twitter</span>
              <Twitter />
            </IconButton>
          </li>
        )}

        {config.data?.linkedin_username && (
          <li className="social__item">
            <IconButton
              className="social__icon"
              buttonStyles={buttonStyles}
              href={`https://linkedin.com/company/${config.data.linkedin_username}`}
              target="_blank"
              rel="noopener"
            >
              <span className="screenreader-text">
                Connect with us on LinkedIn
              </span>
              <Linkedin />
            </IconButton>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default memo(Social)
