import React from 'react'

const MenuClose = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1.70711"
      y1="1.29289"
      x2="19.3848"
      y2="18.9706"
      stroke="#373737"
      strokeWidth="2"
    />
    <line
      x1="1.29289"
      y1="18.9706"
      x2="18.9706"
      y2="1.29296"
      stroke="#373737"
      strokeWidth="2"
    />
  </svg>
)

export default MenuClose
