import React, { useState } from 'react'
import Cookies from 'js-cookie'
import {Link} from "gatsby";
import Button, {ButtonStyle} from "./button";

export const CookieBanner = () => {
  // Have we already accepted cookies?
  const accepted = Cookies.get('gatsby-accepted-cookies') || false

  const [animate, setAnimate] = useState(false)
  const [showCookieBanner, setShowCookieBanner] = useState(!accepted)

  const close = () => {
    setAnimate(true)

    window.setTimeout(() => {
      setShowCookieBanner(false)
    }, 500)
  }

  const accept = () => {
    Cookies.set('gatsby-gdpr-google-analytics', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-google-tagmanager', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-facebook-pixel', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-tiktok-pixel', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-hotjar', 'true', { expires: 30 })
    Cookies.set('gatsby-gdpr-linked-in', 'true', { expires: 30 })
    Cookies.set('gatsby-accepted-cookies', 'true', { expires: 30 })

    close()
  }

  const reject = () => {
    Cookies.remove('gatsby-gdpr-google-analytics', {'path': '/'})
    Cookies.remove('gatsby-gdpr-google-tagmanager', {'path': '/'})
    Cookies.remove('gatsby-gdpr-facebook-pixel', {'path': '/'})
    Cookies.remove('gatsby-gdpr-tiktok-pixel', {'path': '/'})
    Cookies.remove('gatsby-gdpr-hotjar', {'path': '/'})
    Cookies.remove('gatsby-gdpr-linked-in', {'path': '/'})
    Cookies.remove('gatsby-accepted-cookies', {'path': '/'})
    close()
  }

  return (
    <React.Fragment>
      {showCookieBanner && (
        <div
          className={`cookie-banner animate__animated ${
            animate ? ' animate__fadeOutDown' : ''
          } `}
        >
          <div className="cookie-banner__container">
            <div className="cookie-banner__message">
              <div>
                Our website uses cookies. Read our <Link to="/legal/privacy-policy">privacy policy.</Link>
              </div>
            </div>
            <div className="cookie-banner__control">
              <Button
                onClick={accept}
                label="Accept"
                buttonStyles={[ButtonStyle.quaternary, ButtonStyle.arrow]}
                className="cookie-banner__accept"
              />
              <Button
                onClick={reject}
                label="Reject"
                buttonStyles={[ButtonStyle.tertiary, ButtonStyle.arrow]}
                className="cookie-banner__reject"
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
