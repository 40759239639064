import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext } from 'react'
import { ContactPanelContext } from '../context/contact-panel-context-provider'
import SiteConfig from '../types/site-config'
import { ButtonStyle } from './button'
import IconButton from './icon-button'
import Close from './images/close.svg'
import ContactPanelType from '../types/contact-panel'
import { RichText } from '@superrb/gatsby-addons/components'
import { linkResolver } from '../utils/linkResolver'
import ContactPanelImages from './contact-panel/images'

const ContactPanel = () => {
  const data: { config: SiteConfig; contactPanel: ContactPanelType } =
    useStaticQuery(graphql`
      query ContactPanelQuery {
        config: prismicSiteConfig {
          ...LocalSiteConfig
        }
        contactPanel: prismicContactPanel {
          ...ContactPanel
        }
      }
    `)

  const {
    data: { email_address, phone_number },
  } = data.config
  const {
    data: {
      title,
      text,
      contact_info_title,
      vacancies_link,
      vacancies_link_label,
    },
  } = data.contactPanel

  const { contactPanelOpen, closeContactPanel } =
    useContext(ContactPanelContext)

  if (!data?.config?.data || !data?.contactPanel?.data) {
    return null
  }

  return (
    <section
      className={`contact-panel ${
        contactPanelOpen ? 'contact-panel--visible' : ''
      }`}
    >
      <div className="contact-panel__container">
        <ContactPanelImages />
        <IconButton
          className="contact-panel__close"
          onClick={closeContactPanel}
          buttonStyles={[ButtonStyle.secondary, ButtonStyle.circle]}
          label="Close panel"
        >
          <Close />
        </IconButton>

        <h2 className="contact-panel__title">{title}</h2>

        <RichText className="contact-panel__text" field={text.richText} />

        <div className="contact-panel__contact-info">
          <h3 className="contact-panel__contact-info-title">
            {contact_info_title}
          </h3>

          {email_address && (
            <a className="contact-panel__link" href={`mailto:${email_address}`}>
              {email_address}
            </a>
          )}
          {phone_number && (
            <a className="contact-panel__link" href={`tel:${phone_number}`}>
              {phone_number}
            </a>
          )}
          {vacancies_link && (
            <a
              className="contact-panel__link contact-panel__link--underline"
              href={linkResolver(vacancies_link)}
            >
              {vacancies_link_label}
            </a>
          )}
        </div>
      </div>
    </section>
  )
}

export default ContactPanel
