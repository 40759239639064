import { useLockBodyScroll } from '@superrb/gatsby-addons/hooks'
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react'

export const ContactPanelContext = createContext({
  contactPanelOpen: false,
  toggleContactPanel: () => {},
  openContactPanel: () => {},
  closeContactPanel: () => {},
})

export const ContactPanelContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [contactPanelOpen, setContactPanelOpen] = useState<boolean>(false)
  useLockBodyScroll(contactPanelOpen)

  const toggleContactPanel = useCallback(() => {
    setContactPanelOpen((contactPanelOpen) => !contactPanelOpen)
  }, [setContactPanelOpen])

  const openContactPanel = useCallback(() => {
    setContactPanelOpen(true)
  }, [setContactPanelOpen])

  const closeContactPanel = useCallback(() => {
    setContactPanelOpen(false)
  }, [setContactPanelOpen])

  return (
    <ContactPanelContext.Provider
      value={{
        contactPanelOpen,
        toggleContactPanel,
        openContactPanel,
        closeContactPanel,
      }}
    >
      {children}
    </ContactPanelContext.Provider>
  )
}

export default ContactPanelContextProvider
