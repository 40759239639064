import React, { memo } from 'react'
import Button, { ButtonProps, ButtonStyle } from './button'

const IconButton = ({
  className = '',
  label = '',
  buttonStyles = [ButtonStyle.primary],
  children,
  ...props
}: ButtonProps) => (
  <Button
    className={className}
    buttonStyles={[ButtonStyle.icon, ...buttonStyles]}
    label={undefined}
    label_a11y={label}
    {...props}
  >
    {children}
  </Button>
)

export default memo(IconButton)
