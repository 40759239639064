import React, { StrictMode } from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { Layout } from './src/components/layout'
import { repositoryConfigs } from './src/utils/prismicPreviews'
import ContactPanelContextProvider from './src/context/contact-panel-context-provider'

import './src/stylesheets/style.sass'
import { StateInspector } from 'reinspect'
import {
  LazyLoadingContextProvider,
  NavContextProvider,
  PreloadContextProvider,
  TranslationContextProvider,
} from '@superrb/gatsby-addons/context'

export const wrapRootElement = ({ element }) => (
  <StrictMode>
    <StateInspector name="App">
      <PrismicProvider
        internalLinkComponent={({ href, ...props }) => (
          <Link to={href} {...props} />
        )}
      >
        <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
          <PreloadContextProvider>
            <TranslationContextProvider>
              <ContactPanelContextProvider>
                <NavContextProvider>
                  <LazyLoadingContextProvider>
                    <Layout>{element}</Layout>
                  </LazyLoadingContextProvider>
                </NavContextProvider>
              </ContactPanelContextProvider>
            </TranslationContextProvider>
          </PreloadContextProvider>
        </PrismicPreviewProvider>
      </PrismicProvider>
    </StateInspector>
  </StrictMode>
)
