import React, { PropsWithChildren, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { SkipTo } from './skip-to'
import Header from './header'
import Footer from './footer'
import ContactPanel from './contact-panel'
import { BackToTop } from './back-to-top'
import { TranslationContext } from '@superrb/gatsby-addons/context'

export const Layout = ({ children }: PropsWithChildren<{}>) => {
  const { language } = useContext(TranslationContext)

  return (
    <>
      <Helmet htmlAttributes={{ lang: language.slice(0, 2) }}>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
      </Helmet>
      <SkipTo />
      <Header />
      <ContactPanel />
      <main id="content">{children}</main>
      <Footer />
      <BackToTop />
    </>
  )
}
